
import { defineComponent, computed, watch, ref } from 'vue';
import gsap from 'gsap';

export default defineComponent({
    props: ['effect'],
    setup(props) {
        const emitterSize = 20;
        const dotQuantity = 5;
        const dotSizeMin = 20;
        const dotSizeMax = 30;

        const currentValue = computed(() => {
            return props.effect;
        });

        const getRandom = (min: number, max: number) => {
            var rand = min + Math.random() * (max - min);
            return rand;
        };

        const isVisible = ref(true);
        document.addEventListener('visibilitychange', (event) => {
            isVisible.value = document.hidden == false;

            console.log(`EffectConfetti:: ${isVisible.value ? '' : 'NOT '} Visible`);
        });

        const createConfetti = (container: any) => {
            if (isVisible.value == false) {
                console.warn("==== NOT Visible ! - Skippeing confetti ====");
                return;
            }
            // console.log(`createConfetti - x: ${container.width}`);
            const dots = [];
            //var tl = new TimelineLite(); //{paused: true});
            for (let i = 0; i < dotQuantity; i++) {
                let dot = document.createElement('div');
                dots.push(dot);
                dot.className = 'dot';
                let r = getRandom(30, 255);
                let g = getRandom(30, 230);
                let b = getRandom(30, 230);
                gsap.set(dot, {
                    backgroundColor: 'rgb(' + r + ',' + g + ',' + b + ')',
                    visibility: 'visible',
                    width: '20px',
                    height: '10px',
                });
                let size = getRandom(dotSizeMin, dotSizeMax);
                container.appendChild(dot);
                let angle = getRandom(0.65, 0.85) * Math.PI * 2; // a vector pointed up
                // get maximum distance from the center, factoring in size of dot, and then pick a random spot along that vector to plot a point
                let length = Math.random() * (emitterSize / 2 - size / 2);
                let duration = 3 + Math.random();
                //place the dot at a random spot within the emitter, and set its size
                gsap.set(dot, {
                    x: Math.cos(angle) * length,
                    y: Math.sin(angle) * length,
                    width: size,
                    height: size,
                    xPercent: -50,
                    yPercent: -50,
                    // visibility: 'hidden',
                    force3D: true,
                });

                const totalD = 2;
                const fadeD = 0.5;
                const radius = 200;

                /*
                MOVE
            */
                gsap.to(dot, {
                    duration: totalD,
                    y: getRandom(-radius, radius),
                    x: getRandom(-radius, radius),
                    rotation: 360,
                    ease: 'power4.out', // Power4.easeOut
                });
                gsap.to(dot, { duration: totalD - fadeD, opacity: 0 });

                // // tl.to(dot, duration / 2, {
                // //     opacity: 0,
                // //     // ease: RoughEase.ease.config({
                // //     //     points: 20,
                // //     //     strength: 1.75,
                // //     //     clamp: true
                // //     // })
                // // }, 0);

                /*
                SPARCKLE
            */
                gsap.to(dot, {
                    duration: duration,
                    visibility: 'visible',
                    rotationX: '-=' + getRandom(720, 1440),
                    rotationZ: '+=' + getRandom(720, 1440),
                    // physics2D: {
                    //     angle: angle * 180 / Math.PI, // translate radians to degrees
                    //     velocity: (100 + Math.random() * 250) * speed, // initial velocity
                    //     gravity: 700 * gravity,
                    //     friction: getRandom(0.1, 0.15)
                    // }
                });
                gsap.delayedCall(totalD, () => {
                    dot.parentNode?.removeChild(dot);
                });

                // tl.to(dot, 1.25 + Math.random(), {
                //     opacity: 0
                // }, duration / 2);
            }
        };

        const emitter = ref(null);

        const playConfetti = () => {
            // console.log("playConfetti", emitter.value);
            for (var i = 0; i < 3; i++) {
                setTimeout(function () {
                    createConfetti(emitter.value);
                }, i * 500);
            }
        };

        watch(currentValue, () => {
            console.log('Props changed', props.effect);
            // if (props.effect == 0) {
            //     // console.log("Reset changecount")
            //     // let changeCount = 0;
            //     return;
            // }

            playConfetti();
        });

        return {
            emitter,
            playConfetti,
            currentValue,
        };
    },
});
