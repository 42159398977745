
import { defineComponent, onMounted, ref, computed, watch, onBeforeUnmount } from 'vue';
// import api from '../services/api';
// import { ChartModelData } from '@/models/ChartModels';
import moment from 'moment';
import { useRoute } from 'vue-router';
import {useStore} from 'vuex';

// https://school.geekwall.in/p/SkD7MqoSN
// import { TimelineLite, TweenLite } from 'gsap';
// import gsap from 'gsap';

export default defineComponent({
  setup() {
    const route = useRoute();
    const store = useStore();

    const selectedDate = ref(new Date());
    // const confettiVal = ref(0);

    const orderIntake = computed(() => store.state.kpis.filter((k: any) => k.key == 'SR.Aftersales.Orderintake.Spareparts'));
    const invoiced = computed(() => store.state.kpis.filter((k: any) => k.key == 'SR.Aftersales.Invoiced.Spareparts'));
    // const showKpiViews = computed(() => store.state.viewState == "Authorized");
    const budgetDayValue = computed(() => store.state.budgetDayValue);
    const refreshIntervalMs = 30000;

    watch(budgetDayValue, () => {
      console.log("Budget value changed - Reloading...");
      reload(false);
    })

    const reload = (reloadBudget: boolean) => {
        const selectedDay = moment(selectedDate.value.toISOString());
        const company = route.query.company
        const code = route.query.code

        if (reloadBudget) {
            store.commit('resetAftersales');
            // store.commit('addKpi', {kpiId: 10, key: 'SR.Aftersales.Orderintake.Spareparts', period: 'day', label: 'Today'});
            store.commit('addKpi', {kpiId: 11, key: 'SR.Aftersales.Orderintake.Spareparts', period: 'week', label: 'Week to date'});
            store.commit('addKpi', {kpiId: 12, key: 'SR.Aftersales.Orderintake.Spareparts', period: 'month', label: 'Month to date'});

            // store.commit('addKpi', {kpiId: 20, key: 'SR.Aftersales.Invoiced.Spareparts', period: 'day', label: 'Today'});
            store.commit('addKpi', {kpiId: 21, key: 'SR.Aftersales.Invoiced.Spareparts', period: 'week', label: 'Week to date'});
            store.commit('addKpi', {kpiId: 22, key: 'SR.Aftersales.Invoiced.Spareparts', period: 'month', label: 'Month to date'});

            console.log("Loading Sales Budget...", selectedDay.format("DD.MM.YYYY HH:mm:ss"))
            store.dispatch('loadAftersalesBudget', {company, code, selectedDay})
        }
        if (budgetDayValue.value > 0) {
          console.log("Loading Sales Aggregates for year...", selectedDay.format("DD.MM.YYYY HH:mm:ss"));
          store.dispatch('loadAftersalesAggregates', {company, code, selectedDay})

          // console.log("Loading Sales for day...", selectedDay.format("DD.MM.YYYY HH:mm:ss"));
          // store.dispatch('loadAftersalesDay', {company, code, selectedDay})
        }
    };

    let pollingInterval: any = undefined;
    const pollData = () => {
      pollingInterval = setInterval(() => {
        reload(false);
      }, refreshIntervalMs)
    }

    onMounted(() => {
      console.log("== KPI:Aftersales - onMounted");
      // const code = route.query.code
      reload(true);
      pollData();
    });

    onBeforeUnmount(() => {
      clearInterval(pollingInterval);
    });

    const displayDate = () => {
        return moment(selectedDate.value.toISOString()).format("dddd DD.MM.yyyy");
    }

    return {
      orderIntake,
      invoiced,

      displayDate,
      
    }
  }
});
