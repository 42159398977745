
// interface IChartData {
//   labels: Array<string>;
// }

export interface IChartModelData {
  type: string;
  title: string;
  // chart: IChartData;
  labels: Array<string>;
  actualData: Array<number>;
  budgetData: Array<number>;
  budgetDay: number;
  budgetSum: number;

  minyaxis: number;
}

export class ChartModelData implements IChartModelData {
  type: string;
  title: string;
  labels: Array<string>;
  actualData: Array<number>;
  budgetData: Array<number>;
  minyaxis: number;
  budgetDay: number;
  budgetSum: number;

  constructor(title: string, chartType: string) {
    this.type = chartType;
    this.title = title;
    this.labels = new Array<string>();
    this.actualData = new Array<number>();
    this.budgetData = new Array<number>();
    this.minyaxis = 0;
    this.budgetDay = 0;
    this.budgetSum = 0;
  }
}