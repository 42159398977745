
import { defineComponent, onMounted, ref, computed, onBeforeUnmount } from 'vue';
import moment from 'moment';
import { useRoute } from 'vue-router';
import api from '@/services/api';

interface KpiProduction
{
  key: string;
  backlog: number;
  planned: number;
  produced: number;
}

class ProductCounts {
  lbl = "";
  x = 0;
  x_s = 0;
  x_m = 0;
  x_l = 0;
  c = 0;
}

class ProdItemStatus {
  product = "";
  model = "";
  prodLine = "";
  prodOrderNo = "";
  status = "";
  quantity = 1;
  progress = -1;
  hours = 0;
  finishedL4 = "";
}

export default defineComponent({
  setup() {
    const route = useRoute();
    const code = route.query.code;
    // const backlog = route.query.backlog;
    const kpiBaseDate = ref(moment());
    const refreshIntervalMs = 120 * 1000;

    const weekLabel = ref("This Week");

    const kpis = ref({
      backlog: {
        week: new ProductCounts(),
        month: new ProductCounts(),
        year: new ProductCounts(),
      },
      planned: {
        week: new ProductCounts(),
        month: new ProductCounts(),
        year: new ProductCounts()
      },
      produced: {
        last10x: new Array<ProductCounts>(),
        last10c: new Array<ProductCounts>(),
        week: new ProductCounts(),
        month: new ProductCounts(),
        year: new ProductCounts()
      }
    });
    const weekItems = ref(Array<ProdItemStatus>());    

    const stockModels = ref(Array<any>());
    const load2BoxStockStatus = () => {
      const url = process.env.VUE_APP_APIURL + `/api/opf/stock/${code}`;

      api.get(url)
        .then((data: any) => {
          // console.log("2-box Stock", data);
          if (data) {
            stockModels.value = data;
            stockModels.value.forEach((m: any) => {
              const maxCount = m.reorderPoint + m.reorderQuantity;
              m.items = new Array<any>();
              for (let slotNo=1; slotNo<=maxCount; slotNo++) {
                m.items.push({
                  inStock: m.availableQuantity >= slotNo,
                })
              }
            })
            // console.log("2-box Stock Model", stockModels.value);
          } else {
            stockModels.value = [];
          }
          
        })
        .catch((ex) => {
            console.error("2-box Stock", ex);
        });
    }

    const loadCompressorProductionStatusView = () => {
      const baseDay = kpiBaseDate.value.format("YYYY-MM-DD");
      const url = process.env.VUE_APP_APIURL + `/api/opf/productionstatusview/${code}?baseDate=${baseDay}`;

      api.get(url)
        .then((data: any) => {
          console.debug("####>> GOT - Compressor Production Status View", data);
          if (data) {
            
            if (getThisWeekOffset() < 0) {
              weekItems.value = data.weekItems.filter((po :ProdItemStatus) => po.progress > 4);
            } else {
              weekItems.value = data.weekItems;
            }
            kpis.value.produced.last10x = [];
            kpis.value.produced.last10c = [];
            
            for (const kpi of data.kpis) {
              // Using for since forEach is prosecuted async
              if (kpi.key == "xrange.week") {
                // kpis.value.actual.week.x = kpi.actual;
                kpis.value.produced.week.x = kpi.produced;
              }
              if (kpi.key == "xrange.month") {
                // kpis.value.actual.month.x = kpi.actual;
                kpis.value.produced.month.x = kpi.produced;
              }
              if (kpi.key == "xrange.year") {
                // kpis.value.actual.year.x = kpi.actual;
                kpis.value.produced.year.x = kpi.produced;
              }
              
              if (kpi.key == "classic.week") {
                // kpis.value.actual.week.c = kpi.actual;
                kpis.value.produced.week.c = kpi.produced;
              }
              if (kpi.key == "classic.month") {
                // kpis.value.actual.month.c = kpi.actual;
                kpis.value.produced.month.c = kpi.produced;
              }
              if (kpi.key == "classic.year") {
                // kpis.value.actual.year.c = kpi.actual;
                kpis.value.produced.year.c = kpi.produced;
              }
              if (kpi.key.startsWith("xrange.weeks.")) {
                var k1 = new ProductCounts();
                k1.x = kpi.produced;

                // Find historic week number
                const histNum = parseInt(kpi.key.split(".")[2]);                
                const weekDate = kpiBaseDate.value.clone().subtract(histNum, "weeks");
                const weekNum = weekDate.isoWeek();
                // console.log("HistNum", histNum, "WeekNum", weekNum, weekDate.format("DD.MM.YYYY"));
                k1.lbl = `W${weekNum}`;
                kpis.value.produced.last10x.push(k1);
              }
              
              if (kpi.key.startsWith("classic.weeks.")) {
                var k2 = new ProductCounts();
                k2.x = kpi.produced;
                k2.lbl = "w" + kpi.key.split(".")[2];
                kpis.value.produced.last10c.push(k2);
              }

            }
          }
        });
      };

    const weekItemsXRange = computed(() => {
        // return weekItems.value.filter((i: ProdItemStatus) => i.prodLine == 'OPF');
        return weekItems.value.filter((i: ProdItemStatus) => i.product && i.product.toLowerCase() == 'x-range');
    });
    
    const weekItemsClassic = computed(() => {
      // return weekItems.value.filter((i: ProdItemStatus) => i.prodLine == 'Anlegg');
      return weekItems.value.filter((i: ProdItemStatus) => i.product && i.product.toLowerCase() == 'classic');
    });

    const reload = () => {
      load2BoxStockStatus();
      loadCompressorProductionStatusView();
    }

    let pollingInterval: any = undefined;
    const pollData = () => {
      pollingInterval = setInterval(() => {
        console.log("OPF::Auto Reload...");
        const offset = kpiBaseDate.value.isoWeek() - moment().isoWeek();
        if (offset == 0)
          reload();
        else
          console.log("OPF::Skipping reload. Weekoffset: ", offset);
      }, refreshIntervalMs)
    }

    onMounted(() => {
      console.log("OPF::onMounted");
      reload();
      pollData();
    });

    onBeforeUnmount(() => {
      clearInterval(pollingInterval);
    });

    const logItemStatus = (item: any) => {
      console.log("Item clicked", JSON.parse(JSON.stringify(item)));
    }

    const getItemStatus = (item: ProdItemStatus) => {
      // return JSON.stringify(item);
      return { 
        value: `<div>${moment(item.finishedL4).format('DD-MM-YYYY')}</div><div>${item.progress}</div>`, 
        escape: true, 
        class: 'custom-error' 
      }
    }

    const compModel = (str: string) => {
      if (str && str != null) {
        if (str.indexOf('-') > -1) {
          return str.split('-')[0].trim();
        } else if (str.indexOf(':') > -1) {
          return str.split(':')[1].trim();
        } else {
          return str;
        }
      } else {
        return str;
      }
    }

    const getItemStatusStyle = (item: ProdItemStatus) => {
      let statusNo = -1;

      if (item.progress > 4) {
        statusNo = 49;
      } else if (item.progress > 0) {
        statusNo = 11;
      } else if (item.progress == 0) {
        statusNo = 10;
      }

      return `status-${statusNo}`;
    }

    const progressLbl = (str: string) => {
      if (str && str.length > 6) {
        return str.substring(0, 6);
      } else {
        return str;
      }
    }

    const weekInc = (days: number) => {
      kpiBaseDate.value.add(days, "days");
      const offset = kpiBaseDate.value.isoWeek() - moment().isoWeek();
      console.log("Week Change", kpiBaseDate.value.isoWeek(), offset);
      weekLabel.value = offset == 0 ? "This Week" : `Week ${kpiBaseDate.value.isoWeek()}`
      
      loadCompressorProductionStatusView();
    }

    const getThisWeekOffset = () => {
      return kpiBaseDate.value.isoWeek() - moment().isoWeek();
    }

    // const noLabel = {
    //   show: false
    // };
    const topLabel = {
      show: true,
      position: 'top'
    };
    const opfHistoryChartOptions = computed(() => {
      var dataSeries = [];
      dataSeries.push({
          name: "X-Range",
          type: "bar",
          stack: 'x',
          color: "rgb(192, 231, 185, 1.0)",
          radius: "55%",
          center: ["50%", "60%"],
          data: kpis.value.produced.last10x.map((k: any) => k['x'] + k['c']),
          label: topLabel,            
          emphasis: {
              focus: 'series',
              itemStyle: {
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)"
              }
          },
      });
      dataSeries.push({
          name: "Classic",
          type: "bar",
          stack: 'c',
          // color: "rgb(192, 231, 185, 1.0)",
          color: "silver",
          radius: "55%",
          center: ["50%", "60%"],
          data: kpis.value.produced.last10c.map((k: any) => k['x'] + k['c']),
          label: topLabel,            
          emphasis: {
              focus: 'series',
              itemStyle: {
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)"
              }
          },
      });

      return {
        grid: {
          top: '20px',
          left: '20px',
          right: '20px',
            // width: '100%',
        },
        title: {
          show: false,
          text: "Sperre Vessel Types",
          left: "center",
        },
        tooltip: {
          trigger: "item",
          formatter: "<small>Week: {b}, Total: {c}</small>",
          textStyle: {
              color: "black",
          }
        },
        legend: {
          show: true,
          orient: "horizontal",
          align: "auto",
          bottom: 0,
          // textStyle: {            
          //   color: "red",
          // }
        },
        xAxis: {
          type: 'category',
          data: kpis.value.produced.last10x.map((k: any) => k['lbl']),
          axisLabel: {
              fontSize: 14,
          }
        },
        yAxis: [
            {
                type: 'value',
                position: 'left',
                name: ' ',
                nameTextStyle: {
                    fontSize: 14,
                    padding: 5,
                },
                axisLabel: {
                  show: false,
                  fontSize: 14,
                },
                splitLine: {
                    lineStyle: {
                        color: 'rgb(192, 231, 185, 0.25)'
                    }
                },                
            },

        ],
        series: dataSeries,
      }
    });


    return {
      stockModels,
      // producedCompressors,

      kpis,
      weekRemainingXRange: weekItemsXRange,
      weekRemainingClassic: weekItemsClassic,

      logItemStatus,
      getItemStatus,
      compModel,
      progressLbl,
      getItemStatusStyle,
      opfHistoryChartOptions,
      weekInc,
      weekLabel,
    }
  }
});
