
import { defineComponent, onMounted, ref, computed } from 'vue';

// import { ChartModelData } from '@/models/ChartModels';
// import colors from "@/tools/colors";
import api from '../services/api';
import { useRoute } from 'vue-router';

export default defineComponent({
  setup() {
    const route = useRoute();
    const code = route.query.code

    const compressors = ref(Array<any>());
    const pistons = computed(() => compressors.value.filter((c: any) => c.productGroup == 'Piston'));
    // const screws = computed(() => compressors.value.filter((c: any) => c.productGroup == 'Screw'));

    const compare = (a: string, b: string) => {
      if (a > b) return +1;
      if (a < b) return -1;
      return 0;
    };

    const pistonComps = computed(() => {
      const comps = compressors.value
        .slice()
        .filter((c: any) => c.productGroup == "Piston" && c.available_Flag == true && c.location_Code == 'LAGER1')
        .sort((a: any, b: any) => {
          return compare(a.model, b.model) || compare(a.no, b.no);
        });

      return comps;
    });

    const screwComps = computed(() => {
      const comps = compressors.value
        .slice()
        .filter((c: any) => c.productGroup == "Screw" && c.available_Flag == true && c.location_Code == 'LAGER1')
        .sort((a: any, b: any) => {
          return compare(a.model, b.model) || compare(a.no, b.no);
        });

      return comps;
    });

    const loadAvailability = () => {
      const url = process.env.VUE_APP_APIURL + `/api/availability/lager1/${code}`;
      api.get(url)
        .then((data: any) => {
          console.log("Available compressors", data);
          compressors.value = data;
        })
        .catch((ex) => {
            console.error("Available compressors", ex);
        });
    };
    
    onMounted(() => {
      console.log("onMounted");
      loadAvailability();
    });

    return {
      pistons,
      // screws,
      pistonComps,
      screwComps,
    }
  }
});
