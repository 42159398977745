
import { defineComponent, computed, ref, onMounted } from "vue";

export default defineComponent({
  setup() {
    const vessels = ref(Array<number>());
    const ecomarineMapFrame = ref<any>(null);

    vessels.value.push(9632064);
    vessels.value.push(9831309);    

    const sendMessageToEmbed = (imo: number) => {
      console.log(imo);
      if (ecomarineMapFrame.value) {
        const frame = ecomarineMapFrame.value.contentWindow;
        const data = {imoNumber: imo};
        frame.postMessage(
            {
              functionName: 'showInfo',
              params: [data]
            }, '*');
      }
    }

    const filterOnVessels = () => {
      if (ecomarineMapFrame.value) {
        const frame = ecomarineMapFrame.value.contentWindow;
        frame.postMessage(
          {
            functionName: 'showVesselsWithIMO',
            params: [{ imoNumbers: vessels.value }]
          }, '*');
      }
    }

    onMounted(() => {
      console.log(ecomarineMapFrame.value);
    })

    return {
      ecomarineMapFrame,
      vessels,
      sendMessageToEmbed,
      filterOnVessels,
    }
  }
})

