enum colors {
  sperre_black = 'rgba(30, 30, 35, 1.0)',
  sperre_black_60 = 'rgba(30, 30, 35, 0.6)', 
  sperre_black_30 = 'rgba(30, 30, 35, 0.3)', 
  sperre_black_10 = 'rgba(30, 30, 35, 0.1)', 

  sperre_red = 'rgba(255, 40, 0, 1.0)',
  sperre_red_80 = 'rgba(255, 40, 0, 0.8)', // NOT IN EGGS PALETTE
  sperre_red_30 = 'rgba(255, 40, 0, 0.3)',

  sperre_white = 'rgba(255, 255, 255)',

  sperre_green = 'rgba(192, 231, 185, 1.0)',
  sperre_green_75 = 'rgba(192, 231, 185, 0.75)',
  sperre_green_50 = 'rgba(192, 231, 185, 0.50)',
  sperre_green_25 = 'rgba(192, 231, 185, 0.25)',

  sperre_light_blue = 'rgba(159, 221, 237, 1.0)',
  sperre_light_blue_80 = 'rgba(159, 221, 237, 0.8)',
  sperre_light_blue_50 = 'rgba(159, 221, 237, 0.5)',
  sperre_light_blue_30 = 'rgba(159, 221, 237, 0.3)',

  sperre_lilac = 'rgba(217, 206, 241, 1.0)',
}

export default colors;