import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import Home from '../views/Home.vue';
// import AftersalesSAP from '../views/Aftersales.SAP.vue';
import AftersalesSAP2 from '../views/Aftersales.SAP.2.vue';
import WarehouseSAP from '../views/Warehouse.SAP.vue';
import Esg from '../views/Esg.vue';
import AvailabilityLager1 from '../views/Availability.Lager1.vue';
import OfficeSR from '../views/Office.SR.vue';
import OfficeSA from '../views/Office.SA.vue';
import TrialRuns from '../views/TrialRuns.vue';
import VesselMap from '../views/VesselMap.vue';

import Sandbox from '../kpis/sandbox/Sandbox.vue';
import OnePieceFlow from '../kpis/opf/OnePieceFlow.vue';
import ElectroKpi from '../kpis/electro/ElectroKpi.vue';


const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'Home',
        component: Home,
    },
    {
        path: '/aftersales',
        name: 'Aftersales',
        component: AftersalesSAP2,
    },
    {
        path: '/aftersales2',
        name: 'Aftersales2',
        component: AftersalesSAP2,
    },
    {
        path: '/warehouse',
        name: 'Warehouse',
        component: WarehouseSAP,
    },
    {
        path: '/availability',
        name: 'Availability',
        component: AvailabilityLager1,
    },
    {
        path: '/esg',
        name: 'Esg',
        component: Esg,
    },
    {
        path: '/opf',
        name: 'OnePieceFlow',
        component: OnePieceFlow,
    },
    {
        path: '/electro',
        name: 'ElectroKpi',
        component: ElectroKpi,
    },
    {
        path: '/rotterdam',
        name: 'OfficeSR',
        component: OfficeSR,
    },
    {
        path: '/singapore',
        name: 'OfficeSA',
        component: OfficeSA,
    },
    {
        path: '/trialruns',
        name: 'TrialRuns',
        component: TrialRuns,
    },
    {
      path: "/vesselmap",
      name: "VesselMap",
      component: VesselMap,
    },
    {
        path: '/sandbox',
        name: 'Sandbox',
        component: Sandbox,
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

export default router;
