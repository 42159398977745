import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import EffectConfetti from '@/components/EffectConfetti.vue';

import PrimeVue from 'primevue/config';
import Button from 'primevue/button';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
// import Avatar from 'primevue/avatar';
import Tooltip from 'primevue/tooltip';
// import Menu from 'primevue/menu';
// import Listbox from 'primevue/listbox';
// import InputText from 'primevue/inputtext';
// import Breadcrumb from 'primevue/breadcrumb';
// import TabMenu from 'primevue/tabmenu';
import Card from 'primevue/card';
// import Dropdown from 'primevue/dropdown';
// import OverlayPanel from 'primevue/overlaypanel';
// import Tag from 'primevue/tag';
// import Chart from 'primevue/chart';
// import ScrollPanel from 'primevue/scrollpanel';
// import Timeline from 'primevue/timeline';
// import Skeleton from 'primevue/skeleton';
import Calendar from 'primevue/calendar';
// import ToastService from 'primevue/toastservice';
// import Badge from 'primevue/badge';
// import Checkbox from 'primevue/checkbox';
// import MultiSelect from 'primevue/multiselect';

import 'primevue/resources/themes/saga-blue/theme.css'; //theme
import 'primevue/resources/primevue.min.css'; //core css
import 'primeicons/primeicons.css'; //icons

import "./style.scss";

import ProductIcon from "@/components/ProductIcon.vue";
import ActualVsBudgetCard from "@/components/ActualVsBudgetCard.vue"
import LastUpdatedBadge from '@/components/LastUpdatedBadge.vue';

/*
  ECharts
  - https://github.com/ecomfe/vue-echarts#readme
  - https://echarts.apache.org/examples/en/index.html#chart-type-pie
*/
import ECharts from 'vue-echarts'
import { use } from "echarts/core"
// import ECharts modules manually to reduce bundle size
import { CanvasRenderer } from 'echarts/renderers'
import { BarChart } from 'echarts/charts'
import { PieChart } from "echarts/charts";
import { LineChart } from "echarts/charts";
import { GaugeChart } from 'echarts/charts';
import { LegendComponent, TitleComponent, GridComponent, TooltipComponent, DatasetComponent, MarkLineComponent, DataZoomComponent } from 'echarts/components'
use([
  CanvasRenderer,
  BarChart,
  PieChart,
  LineChart,
  GaugeChart,
  GridComponent,
  TooltipComponent,
  LegendComponent,
  TitleComponent,
  MarkLineComponent,
  DataZoomComponent,
  DatasetComponent,
])

const app = createApp(App);

app.component('EffectConfetti', EffectConfetti);
app.component("ProductIcon", ProductIcon);
app.component("ActualVsBudgetCard", ActualVsBudgetCard);
app.component('LastUpdatedBadge', LastUpdatedBadge);


/*
  PrimeVUE Components
*/
// https://www.primefaces.org/primevue/showcase/#/setup
app.use(PrimeVue);
app.component('Button', Button);
app.component('DataTable', DataTable);
app.component('Column', Column);
// app.component('Avatar', Avatar);
// app.component('Menu', Menu);
// app.component('Listbox', Listbox);
// app.component('InputText', InputText);
// app.component('Breadcrumb', Breadcrumb);
// app.component('TabMenu', TabMenu);
app.component('Card', Card);
// app.component('Dropdown', Dropdown);
// app.component('OverlayPanel', OverlayPanel);
// app.component('Tag', Tag);
// app.component('Chart', Chart);
// app.component('ScrollPanel', ScrollPanel);
// app.component('Timeline', Timeline);
app.component('Calendar', Calendar);
// app.component('Skeleton', Skeleton);
// app.component('Checkbox', Checkbox);
// app.component('MultiSelect', MultiSelect);
app.directive('tooltip', Tooltip);

/*
  ECharts
*/
app.component('VChart', ECharts)

console.log("== MAIN > NODE_ENV: " + process.env.NODE_ENV);
app.use(store).use(router).mount('#app');
