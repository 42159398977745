
import { defineComponent, onMounted, ref, computed, onBeforeUnmount } from 'vue';
import moment from 'moment';
import api from '@/services/api';

export default defineComponent({
  setup() {

    const lastChange = ref("- WILL NOT WORK YET -");

    const loadLatestChanges = () => {
      const url = process.env.VUE_APP_APIURL + `/api/latestchange`;

      api.get(url)
        .then((data: any) => {
          console.log("Lastest Table Changes:", data);
        })
    };    

    onMounted(() => {
      loadLatestChanges();
    })

    return {
      lastChange,
    }
  }
});
