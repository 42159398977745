
import { defineComponent, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
// import { usePrimeVue } from "primevue/config";
// import colors from "@/tools/colors";
import api from '../services/api';

// class TrialRun {
//   serialNumber = "";
// }

export default defineComponent({
  setup() {
    const route = useRoute();
    const code = route.query.code;
    const trialRuns = ref(Array<any>());

    const loadTrialRuns = () => {
      const url = process.env.VUE_APP_APIURL + `/api/trial/${code}`;

      api.get(url)
        .then((data: any) => {
          console.debug("Trial Runs", data);
          trialRuns.value = data;
        })
        .catch((ex) => {
            console.error("Trial Runs", ex);
        });
    }

    onMounted(() => {
      console.log("onMounted");
      loadTrialRuns();

    });

    return {
      trialRuns,
    }
  }
});
