
import { defineComponent, onMounted, ref, computed, onBeforeUnmount } from 'vue';
import moment from 'moment';
import { useRoute } from 'vue-router';
import api from '@/services/api';

interface KpiElectro
{
  key: string;
  inProgress: number;
  finished: number;
  diffRouting: number;
}

class ProductCounts {
  lbl = "";
  x = 0;
  x_s = 0;
  x_m = 0;
  x_l = 0;
}

class ProdItemStatus {
  product = "";
  model = "";
  prodLine = "";
  prodOrderNo = "";
  status = "";
  quantity = 1;
  progress = -1;
  hours = 0;
  size = "";
  finishedL4 = "";
}

class RoutingStat {
  lbl = "";
  value = 0;
  group = "";
}

export default defineComponent({
  setup() {
    const route = useRoute();
    const code = route.query.code;
    // const backlog = route.query.backlog;
    const kpiBaseDate = ref(moment());
    const refreshIntervalMs = 120 * 1000;

    const weekLabel = ref("This Week");

    const kpis = ref({
      inProgress: {
        week: new ProductCounts(),
        month: new ProductCounts(),
        year: new ProductCounts()
      },
      finished: {
        last10x: new Array<ProductCounts>(),
        last10c: new Array<ProductCounts>(),
        week: new ProductCounts(),
        month: new ProductCounts(),
        year: new ProductCounts()
      }
    });
    const weekItems = ref(Array<ProdItemStatus>());    
    const routingProductivity = ref(Array<RoutingStat>());
    const routingProductivityLast10 = computed(() => routingProductivity.value.filter((rp: RoutingStat) => rp.group == "10"));
    const routingProductivityLast100 = computed(() => routingProductivity.value.filter((rp: RoutingStat) => rp.group == "100"));

    const stockModels = ref(Array<any>());
    const load2BoxStockStatus = () => {
      const url = process.env.VUE_APP_APIURL + `/api/opf/stock/${code}`;

      api.get(url)
        .then((data: any) => {
          // console.log("2-box Stock", data);
          if (data) {
            stockModels.value = data;
            stockModels.value.forEach((m: any) => {
              const maxCount = m.reorderPoint + m.reorderQuantity;
              m.items = new Array<any>();
              for (let slotNo=1; slotNo<=maxCount; slotNo++) {
                m.items.push({
                  inStock: m.availableQuantity >= slotNo,
                })
              }
            })
            // console.log("2-box Stock Model", stockModels.value);
          } else {
            stockModels.value = [];
          }
          
        })
        .catch((ex) => {
            console.error("2-box Stock", ex);
        });
    }

    const loadCompressorProductionStatusView = () => {
      const baseDay = kpiBaseDate.value.format("YYYY-MM-DD");
      const url = process.env.VUE_APP_APIURL + `/api/opf/electrostatusview/${code}?baseDate=${baseDay}`;

      api.get(url)
        .then((data: any) => {
          console.debug("####>> GOT - Compressor Electro Status View", data);
          if (data) {
            const offset = getThisWeekOffset();
            if (offset == 0) {
              weekItems.value = data.weekItems;
            } else if (offset < 0) {
              weekItems.value = data.weekItems.filter((i: ProdItemStatus) => i.progress >= 4);
            } else {
              weekItems.value = [];
            }
            kpis.value.finished.last10x = [];
            kpis.value.finished.last10c = [];
            routingProductivity.value = [];
            data.kpis.forEach((kpi: KpiElectro) => {
              if (kpi.key == "cabinet.week") {
                kpis.value.inProgress.week.x = offset == 0 ? kpi.inProgress : 0;
                kpis.value.finished.week.x = kpi.finished;
              }
              if (kpi.key == "cabinet.month") {
                kpis.value.inProgress.month.x = kpi.inProgress;
                kpis.value.finished.month.x = kpi.finished;
              }
              if (kpi.key == "cabinet.year") {
                kpis.value.inProgress.year.x = kpi.inProgress;
                kpis.value.finished.year.x = kpi.finished;
              }
              if (kpi.key.startsWith("cabinet.weeks.")) {
                const weekNum = kpiBaseDate.value.isoWeek();
                var k1 = new ProductCounts();
                k1.x = kpi.finished;
                const histNum = parseInt(kpi.key.split(".")[2]);
                k1.lbl = `W${weekNum - histNum}`;
                kpis.value.finished.last10x.push(k1);
              }

              if (kpi.key.startsWith("routing.10")) {
                var strs = kpi.key.split('.');
                if (strs.length == 3) {
                  var rs = new RoutingStat();
                  rs.group = strs[1];
                  rs.lbl = strs[2];
                  rs.value = kpi.diffRouting * -1; // Invert indication
                  routingProductivity.value.push(rs);
                }
              }

            });
          }
        });
      };

    const weekItemsCabinetInProgress = computed(() => {
        return weekItems.value.filter((i: ProdItemStatus) => i.progress < 4 && i.product && i.product.toLowerCase() == 'cabinet');
    });

    const weekItemsCabinetFinished = computed(() => {
        return weekItems.value.filter((i: ProdItemStatus) => i.progress >= 4 && i.product && i.product.toLowerCase() == 'cabinet');
    });

    const reload = () => {
      load2BoxStockStatus();
      loadCompressorProductionStatusView();
    }

    let pollingInterval: any = undefined;
    const pollData = () => {
      pollingInterval = setInterval(() => {
        console.log("OPF::Auto Reload...");
        const offset = kpiBaseDate.value.isoWeek() - moment().isoWeek();
        if (offset == 0)
          reload();
        else
          console.log("OPF::Skipping reload. Weekoffset: ", offset);
      }, refreshIntervalMs)
    }

    onMounted(() => {
      console.log("OPF::onMounted");
      reload();
      // pollData();
    });

    onBeforeUnmount(() => {
      clearInterval(pollingInterval);
    });

    const logItemStatus = (item: any) => {
      console.log("Item clicked", JSON.parse(JSON.stringify(item)));
    }

    const getItemStatus = (item: ProdItemStatus) => {
      // return JSON.stringify(item);
      return { 
        value: `<div>${moment(item.finishedL4).format('DD-MM-YYYY')}</div><div>${item.progress}</div>`, 
        escape: true, 
        class: 'custom-error' 
      }
    }

    const compModel = (str: string) => {
      if (str && str != null) {
        if (str.indexOf('-') > -1) {
          return str.split('-')[0].trim();
        } else if (str.indexOf(':') > -1) {
          return str.split(':')[1].trim();
        } else {
          return str;
        }
      } else {
        return str;
      }
    }

    const getItemStatusStyle = (item: ProdItemStatus) => {
      let statusNo = -1;

      if (item.progress > 4) {
        statusNo = 49;
      } else if (item.progress > 0) {
        statusNo = 11;
      } else if (item.progress == 0) {
        statusNo = 10;
      }

      return `status-${statusNo}`;
    }

    const progressLbl = (str: string) => {
      if (str && str.length > 6) {
        return str.substring(0, 6);
      } else {
        return str;
      }
    }

    const weekInc = (days: number) => {
      kpiBaseDate.value.add(days, "days");
      const offset = kpiBaseDate.value.isoWeek() - moment().isoWeek();
      console.log("Week Change", kpiBaseDate.value.isoWeek(), offset);
      weekLabel.value = offset == 0 ? "This Week" : `Week ${kpiBaseDate.value.isoWeek()}`
      
      loadCompressorProductionStatusView();
    }

    const getThisWeekOffset = () => {
      return kpiBaseDate.value.isoWeek() - moment().isoWeek();
    }

    // const noLabel = {
    //   show: false
    // };
    const topLabel = {
      show: true,
      position: 'top'
    };
    const opfHistoryChartOptions = computed(() => {
      var dataSeries = [];
      dataSeries.push({
          name: "# Cabinets finished",
          type: "bar",
          stack: 'x',
          color: "rgb(192, 231, 185, 1.0)",
          radius: "55%",
          center: ["50%", "60%"],
          data: kpis.value.finished.last10x.map((k: any) => k['x']),
          label: topLabel,            
          emphasis: {
              focus: 'series',
              itemStyle: {
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)"
              }
          },
      });
      // dataSeries.push({
      //     name: "Classic",
      //     type: "bar",
      //     stack: 'c',
      //     // color: "rgb(192, 231, 185, 1.0)",
      //     color: "silver",
      //     radius: "55%",
      //     center: ["50%", "60%"],
      //     data: kpis.value.finished.last10c.map((k: any) => k['x'] + k['c']),
      //     label: topLabel,            
      //     emphasis: {
      //         focus: 'series',
      //         itemStyle: {
      //         shadowOffsetX: 0,
      //         shadowColor: "rgba(0, 0, 0, 0.5)"
      //         }
      //     },
      // });

      return {
        grid: {
          top: '20px',
          left: '20px',
          right: '20px',
            // width: '100%',
        },
        title: {
          show: false,
          text: "Sperre Vessel Types",
          left: "center",
        },
        tooltip: {
          trigger: "item",
          formatter: "<small>Week: {b}, Total: {c}</small>",
          textStyle: {
              color: "black",
          }
        },
        legend: {
          show: true,
          orient: "horizontal",
          align: "auto",
          bottom: 0,
          // textStyle: {            
          //   color: "red",
          // }
        },
        xAxis: {
          type: 'category',
          data: kpis.value.finished.last10x.map((k: any) => k['lbl']),
          axisLabel: {
              fontSize: 14,
          }
        },
        yAxis: [
            {
                type: 'value',
                position: 'left',
                name: ' ',
                nameTextStyle: {
                    fontSize: 14,
                    padding: 5,
                },
                axisLabel: {
                  show: false,
                  fontSize: 14,
                },
                splitLine: {
                    lineStyle: {
                        color: 'rgb(192, 231, 185, 0.25)'
                    }
                },                
            },

        ],
        series: dataSeries,
      }
    });

    const gaugeChartConfig = (group: string, gaugeLbl: string) => {
      const rp = routingProductivity.value.find((r: RoutingStat) => r.group == group && r.lbl == gaugeLbl) || new RoutingStat();
      return {
        
        series: [
          {
            type: 'gauge',
            startAngle: 180,
            endAngle: 0,
            // center: ['50%', '75%'],
            center: ['50%', '80%'],
            radius: '150%',
            min: -50,
            max: 50,
            splitNumber: 10,
            axisLine: {
              lineStyle: {
                width: 10,
                color: [
                  [0.40, '#FF6E76'],
                  [0.60, '#FDDD60'],
                  [1, '#7CFFB2']
                ]
              }
            },
            pointer: {
              icon: 'path://M12.8,0.7l12,40.1H0.7L12.8,0.7z',
              length: '20%',
              width: 20,
              offsetCenter: [0, '-60%'],
              itemStyle: {
                // color: 'auto'
                color: 'black'
              }
            },
            axisTick: {        
              length: 4,
              lineStyle: {
                color: 'auto',
                width: 1
              }
            },
            splitLine: {
              length: 8,
              lineStyle: {
                color: 'auto',
                width: 4
              }
            },
            axisLabel: {
              show: false,
              // color: '#464646',
              // fontSize: 20,
              // distance: -60,
              // rotate: 'tangential',
              // formatter: function (value: number) {
              //   if (value === 0.875) {
              //     return 'Grade A';
              //   } else if (value === 0.625) {
              //     return 'Grade B';
              //   } else if (value === 0.375) {
              //     return 'Grade C';
              //   } else if (value === 0.125) {
              //     return 'Grade D';
              //   }
              //   return '';
              // }
            },
            title: {
              offsetCenter: [0, '-5%'],
              fontSize: 16
            },
            detail: {
              fontSize: 20,
              offsetCenter: [0, '-35%'],
              valueAnimation: true,
              formatter: function (value: number) {
                return value.toFixed(1) + '%'; // Math.round(value) + '';
              },
              // color: 'inherit',
              color: 'black',
            },
            data: [
              {
                value: rp.value,
                name: rp.lbl
              }
            ]
          }
        ]
      }
    }


    return {
      stockModels,
      // producedCompressors,

      kpis,
      weekItemsCabinetInProgress,
      weekItemsCabinetFinished,

      logItemStatus,
      getItemStatus,
      compModel,
      progressLbl,
      getItemStatusStyle,
      opfHistoryChartOptions,
      weekInc,
      weekLabel,
      // routingProductivity,
      routingProductivityLast10,
      routingProductivityLast100,
      gaugeChartConfig,
    }
  }
});
