
import { defineComponent, onMounted, computed } from 'vue'
import {useStore} from 'vuex'
// import moment from 'moment'
// // import si from '@/tools/si'
// import api from "../services/api";
import { useRoute } from 'vue-router'
// import { usePrimeVue } from "primevue/config";
// import { whileStatement } from '@babel/types';

export default defineComponent({
  setup() {
    // const primevue = usePrimeVue();
    // // https://www.primefaces.org/primevue/showcase/#/locale
    // primevue.config.locale.firstDayOfWeek = 1;
    const route = useRoute()
    const store = useStore();

    const kpis = computed(() => store.state.esgKpis);
    
    const reload = () => {
        const company = route.query.company
        const code = route.query.code
        store.dispatch('loadEsgKpiData', {company, code})
    };

    const numberWithCommas = (x: any) => {
        // Crashes on Safari
        //https://caniuse.com/js-regexp-lookbehind
        //return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ");
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }

    // const evaluateBudget = (agg) => {
    //   return agg.budget > 0 && agg.actual >= agg.budget;
    // }

    onMounted(() => {
      console.log("== KPI:Aftersales - onMounted");
      // const code = route.query.code
      reload();
    //   pollData();
    });

    const noLabel = {
      show: false
    };
    const lineLabel = {
      show: true,
      fontSize: 14,
      color: "white",
      formatter: function(data: any) {
        console.log(">>> VALUE", data);
        return parseFloat(data.value).toFixed(2);
      }
    };

    // const labelOption = {
    //   show: false,
    //   position: 'insideBottom',
    //   distance: 15,
    //   align: 'left',
    //   verticalAlign: 'middle',
    //   rotate: 90,
    //   formatter: '{c}',
    // //   formatter: function (data: any) {
    // //     // si.log("Echart formatter", value)
    // //     return data.value == '0' ? '' : data.value
    // //   },
    //   fontSize: 12,
    //   rich: {
    //     name: {}
    //   },
    //   color: "white",
    // };

    const esgEnergyChartOptions = computed(() => {
      var dataSeries = [];
        dataSeries.push({
            name: kpis.value[1]?.name,
            type: "bar",
            stack: 'stack',
            color: "rgb(255, 40, 0)",
            radius: "55%",
            center: ["50%", "60%"],
            data: kpis.value[1]?.yearValues.map((k: any) => k['value']),
            label: noLabel,
            yAxisIndex: 0,
            emphasis: {
                focus: 'series',
                itemStyle: {
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)"
                }
            },
        });
        dataSeries.push({
            name: kpis.value[0]?.name,
            type: "bar",
            stack: 'stack',
            color: "rgb(192, 231, 185)",
            radius: "55%",
            center: ["50%", "60%"],
            data: kpis.value[0]?.yearValues.map((k: any) => k['value']),
            label: noLabel,
            yAxisIndex: 0,
            emphasis: {
                focus: 'series',
                itemStyle: {
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)"
                }
            },
        });
        dataSeries.push({
            name: kpis.value[3]?.name,
            type: 'line',
            stack: 'stack2',
            color: "grey",
            radius: "55%",
            center: ["50%", "60%"],
            data: kpis.value[3]?.yearValues.map((k: any) => k['value']),
            label: noLabel,
            yAxisIndex: 1,
            emphasis: {
                focus: 'series',
                itemStyle: {
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)"
                }
            },
            symbolSize: 10,
            symbol: 'circle',
            lineStyle: {
                width: 5,
                shadowColor: 'rgba(0,0,0,0.3)',
                shadowBlur: 10,
                shadowOffsetY: 8
            }
        });

      return {
        grid: {
            bottom: 100,
        },
        title: {
          show: false,
          text: "Sperre Vessel Types",
          left: "center",
        },
        tooltip: {
          trigger: "item",
          formatter: "<small>{b} : {c}</small>",
          textStyle: {
              color: "black",
          }
        },
        // toolbox: {
        //     feature: {
        //         dataView: { show: true, readOnly: false },
        //         magicType: { show: true, type: ['line', 'bar'] },
        //         restore: { show: true },
        //         saveAsImage: { show: true }
        //     }
        // },
        legend: {
          orient: "vertical",
          bottom: 0,
          textStyle: {
              color: "white",
          }
        },
        xAxis: {
          type: 'category',
          data: kpis.value[0]?.yearValues.map((k: any) => k['year']),
          axisLabel: {
              color: "white",
              fontSize: 14,
          }
        },
        yAxis: [
            {
                type: 'value',
                position: 'left',
                name: 'GWH',
                //nameLocation: 'middle',
                nameTextStyle: {
                    color: "white",
                    fontSize: 14,
                    padding: 5,
                },
                axisLabel: {
                    color: "white",
                    fontSize: 14,
                },
                splitLine: {
                    lineStyle: {
                        color: 'rgb(192, 231, 185, 0.25)'
                    }
                },                
            },
            {
                type: 'value',
                position: 'right',
                name: 'GWh / NOK',
                min: 0,
                max: 20,
                //nameLocation: 'middle',
                nameTextStyle: {
                    color: "white",
                    fontSize: 14,
                    padding: 5,
                },
                axisLabel: {
                    color: "white",
                    fontSize: 14,
                },
                splitLine: {
                    lineStyle: {
                        color: 'rgb(192, 231, 185, 0.25)'
                    }
                },        
            },

        ],
        series: dataSeries,
      }
    });    

    const esgWasteChartOptions = computed(() => {
      var dataSeries = [];
        dataSeries.push({
            name: kpis.value[7]?.name,
            type: "bar",
            stack: 'stack',
            color: "rgb(159, 221, 237)",
            radius: "55%",
            center: ["50%", "60%"],
            data: kpis.value[7]?.yearValues.map((k: any) => k['value']),
            label: noLabel,
            emphasis: {
                focus: 'series',
                itemStyle: {
                // shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)"
                }
            },
        });
        dataSeries.push({
            name: kpis.value[8]?.name,
            type: 'line',
            stack: 'stack2',
            color: "grey",
            radius: "55%",
            center: ["50%", "60%"],
            data: kpis.value[8]?.yearValues.map((k: any) => k['value']),
            label: noLabel,
            yAxisIndex: 1,
            emphasis: {
                focus: 'series',
                itemStyle: {
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)"
                }
            },
            symbolSize: 10,
            symbol: 'circle',
            lineStyle: {
                width: 5,
                shadowColor: 'rgba(0,0,0,0.3)',
                shadowBlur: 10,
                shadowOffsetY: 8
            }
        });

      return {
        grid: {
            bottom: 100,
        },
        title: {
          show: false,
          text: "Sperre Vessel Types",
          left: "center",
        },
        tooltip: {
          trigger: "item",
          formatter: "<small>{b} : {c}</small>",
          textStyle: {
              color: "black",
          }
        },
        legend: {
          orient: "vertical",
          bottom: 0,
          textStyle: {
              color: "white",
          }
        },
        xAxis: {
          type: 'category',
          data: kpis.value[7]?.yearValues.map((k: any) => k['year']),
          axisLabel: {
              color: "white",
              fontSize: 14,
          }
        },
        // yAxis: {
        //   type: 'value',
        //   position: 'left',
        //   name: 'Ton',
        //   nameTextStyle: {
        //       color: "white",
        //       fontSize: 14,
        //       margin: 5,
        //   },
        //   axisLabel: {
        //       color: "white",
        //       fontSize: 14,
        //   }
        // },
        yAxis: [
            {
                type: 'value',
                position: 'left',
                name: 'Ton',
                //nameLocation: 'middle',
                nameTextStyle: {
                    color: "white",
                    fontSize: 14,
                    padding: 5,
                },
                axisLabel: {
                    color: "white",
                    fontSize: 14,
                },
                splitLine: {
                    lineStyle: {
                        color: 'rgb(192, 231, 185, 0.25)'
                    }
                },                
            },
            {
                type: 'value',
                position: 'right',
                name: 'Ton / NOK',
                min: 0,
                max: 2,
                //nameLocation: 'middle',
                nameTextStyle: {
                    color: "white",
                    fontSize: 14,
                    padding: 5,
                },
                axisLabel: {
                    color: "white",
                    fontSize: 14,
                },
                splitLine: {
                    lineStyle: {
                        color: 'rgb(192, 231, 185, 0.25)'
                    }
                }          
            },

        ],
        series: dataSeries,
      }
    });

    const esgCO2ChartOptions = computed(() => {
      var dataSeries = [];
        dataSeries.push({
            name: kpis.value[4]?.name,
            type: "bar",
            stack: 'stack',
            color: "rgb(255, 40, 0, 1.0)",
            radius: "55%",
            center: ["50%", "60%"],
            data: kpis.value[4]?.yearValues.map((k: any) => k['value']),
            label: noLabel,
            emphasis: {
                focus: 'series',
                itemStyle: {
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)"
                }
            },
        });
        dataSeries.push({
            name: kpis.value[5]?.name,
            type: "bar",
            stack: 'stack',
            color: "rgb(192, 231, 185)",
            radius: "55%",
            center: ["50%", "60%"],
            data: kpis.value[5]?.yearValues.map((k: any) => k['value']),
            label: noLabel,
            emphasis: {
                focus: 'series',
                itemStyle: {
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)"
                }
            },
        });
        dataSeries.push({
            name: kpis.value[6]?.name,
            type: "bar",
            stack: 'stack',
            color: "rgb(159, 221, 237)",
            radius: "55%",
            center: ["50%", "60%"],
            data: kpis.value[6]?.yearValues.map((k: any) => k['value']),
            label: noLabel,
            emphasis: {
                focus: 'series',
                itemStyle: {
                // shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)"
                }
            },
        });
        

      return {
        grid: {
            bottom: 100,
        },
        title: {
          show: false,
          text: "Sperre Vessel Types",
          left: "center",
        },
        tooltip: {
          trigger: "item",
          formatter: "<small>{b} : {c}</small>",
          textStyle: {
              color: "black",
          }
        },
        legend: {
          bottom: 0,
          orient: "vertical",
          textStyle: {
              color: "white",
          }
        },
        xAxis: {
          type: 'category',
          data: kpis.value[4]?.yearValues.map((k: any) => k['year']),
          axisLabel: {
              color: "white",
              fontSize: 14,
          }
        },
        yAxis: {
          type: 'value',
          position: 'left',
          name: 'Ton',
          //nameLocation: 'middle',
          nameTextStyle: {
              color: "white",
              fontSize: 14,
              padding: 5,
          },
          axisLabel: {
              color: "white",
              fontSize: 14,
          },
          splitLine: {
            lineStyle: {
                color: 'rgb(192, 231, 185, 0.25)'
            }
          }
        },
        series: dataSeries,
      }
    });

    

    const esgRecyclingRateChartOptions = computed(() => {
      var dataSeries = [];
        dataSeries.push({
            name: kpis.value[9]?.name,
            type: "line",
            stack: 'stack',
            color: "grey",
            radius: "55%",
            center: ["50%", "60%"],
            data: kpis.value[9]?.yearValues.map((k: any) => k['value']),
            label: lineLabel,
            emphasis: {
                focus: 'series',
                itemStyle: {
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)"
                }
            },
            symbolSize: 10,
            symbol: 'circle',
            lineStyle: {
                width: 5,
                shadowColor: 'rgba(0,0,0,0.3)',
                shadowBlur: 10,
                shadowOffsetY: 8
            }
        });
                

      return {
        grid: {
            bottom: 100,
        },
        title: {
          show: false,
          text: "Sperre Vessel Types",
          left: "center",
        },
        tooltip: {
          trigger: "item",
          formatter: "<small>{b} : {c}</small>",
          textStyle: {
              color: "black",
          }
        },
        legend: {
          bottom: 0,
          orient: "vertical",
          textStyle: {
              color: "white",
          }
        },
        xAxis: {
          type: 'category',
          data: kpis.value[4]?.yearValues.map((k: any) => k['year']),
          axisLabel: {
              color: "white",
              fontSize: 14,
          }
        },
        yAxis: {
          type: 'value',
          position: 'left',
          name: 'Rate factor',
          //nameLocation: 'middle',
          nameTextStyle: {
              color: "white",
              fontSize: 14,
              padding: 5,
          },
          axisLabel: {
              color: "white",
              fontSize: 14,
          },
          splitLine: {
            lineStyle: {
                color: 'rgb(192, 231, 185, 0.25)'
            }
          }
        },
        series: dataSeries,
      }
    });

    return {
        kpis,
        numberWithCommas,
        esgEnergyChartOptions,
        esgWasteChartOptions,
        esgCO2ChartOptions,
        esgRecyclingRateChartOptions,
    };
  },

});
