
import { defineComponent, onMounted, computed, watch, ref, onBeforeUnmount } from 'vue';
import {useStore} from 'vuex';
import moment from 'moment';
import { useRoute } from 'vue-router';
import { usePrimeVue } from "primevue/config";
// import colors from "@/tools/colors";

export default defineComponent({
  setup() {
    const primevue = usePrimeVue();
    // https://www.primefaces.org/primevue/showcase/#/locale
    if (primevue.config && primevue.config.locale) {
        primevue.config.locale.firstDayOfWeek = 1;
    }
    const route = useRoute()
    const store = useStore();

    const selectedDate = ref(new Date());
    const lastUpdatedDate = ref(new Date());
    const selectedDay = computed(() => {
        // console.log("Date Changed to:", selectedDate.value)
        return moment(selectedDate.value.toISOString());
        // return moment(); // moment("2022-02-23") //selectedDate.value)
    }); //moment("2021-08-30");


    watch(selectedDate, () => {
        console.log("New Date", selectedDay.value.format("DD-MM-yyyy"));
        store.commit('updateSelectedDate', selectedDate.value);
        reload(true);
    });

    const kpis = computed(() => store.state.kpis);
    const orderIntake = computed(() => store.state.kpis.filter((k: any) => k.key == 'Aftersales.Orderintake.Spareparts'));
    const invoiced = computed(() => store.state.kpis.filter((k: any) => k.key == 'Aftersales.Invoiced.Spareparts'));
    const showKpiViews = computed(() => store.state.viewState == "Authorized");
    const budgetLoaded = computed(() => store.state.budgetLoaded);
    const budgetDayValue = computed(() => store.state.budgetDayValue);
    const refreshIntervalMs = 30000;

    watch(kpis, () => {
        console.debug("KPIs changed!", kpis.value);
        
    });

    watch(budgetDayValue, () => {
      console.log("Budget value changed - Reloading...");
      reload(false);
    })

    const reload = (reloadBudget: boolean) => {
        const selectedDay = moment(selectedDate.value.toISOString());
        const company = route.query.company
        const code = route.query.code

        if (reloadBudget) {
            store.commit('resetAftersales');
            store.commit('addKpi', {kpiId: 10, key: 'Aftersales.Orderintake.Spareparts', period: 'day', label: 'Today'});
            store.commit('addKpi', {kpiId: 11, key: 'Aftersales.Orderintake.Spareparts', period: 'week', label: 'Week to date'});
            store.commit('addKpi', {kpiId: 12, key: 'Aftersales.Orderintake.Spareparts', period: 'month', label: 'Month to date'});

            store.commit('addKpi', {kpiId: 20, key: 'Aftersales.Invoiced.Spareparts', period: 'day', label: 'Today'});
            store.commit('addKpi', {kpiId: 21, key: 'Aftersales.Invoiced.Spareparts', period: 'week', label: 'Week to date'});
            store.commit('addKpi', {kpiId: 22, key: 'Aftersales.Invoiced.Spareparts', period: 'month', label: 'Month to date'});

            console.log("Loading Sales Budget...", selectedDay.format("DD.MM.YYYY HH:mm:ss"))
            store.dispatch('loadAftersalesBudget', {company, code, selectedDay})
        }
        if (budgetLoaded.value) {
          console.log("Loading Sales Aggregates for year...", selectedDay.format("DD.MM.YYYY HH:mm:ss"));
          store.dispatch('loadAftersalesAggregates', {company, code, selectedDay})

          console.log("Loading Sales for day...", selectedDay.format("DD.MM.YYYY HH:mm:ss"));
          store.dispatch('loadAftersalesDay', {company, code, selectedDay})
        }
    };

    // const numberWithCommas = (x: number) => {
    //     // Crashes on Safari
    //     //https://caniuse.com/js-regexp-lookbehind
    //     //return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ");
    //     return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");

    // }

    // const evaluateBudget = (agg: any) => {
    //   return agg.budget > 0 && agg.actual >= agg.budget;
    // }

    let pollingInterval: any = undefined;
    const pollData = () => {
      pollingInterval = setInterval(() => {
        console.log("Aftersales::Auto Reload...");
        reload(false);
      }, refreshIntervalMs)
    }

    onMounted(() => {
      console.log("== KPI:Aftersales - onMounted");
      // const code = route.query.code
      reload(true);
      pollData(); //<------ Remove to refresh
    });

    onBeforeUnmount(() => {
      clearInterval(pollingInterval);
    });

    const displayDate = () => {
        return moment(selectedDate.value.toISOString()).format("dddd DD.MM.yyyy");
    }

    const testClick = () => {
        orderIntake.value[0].change++;
    }

    return {
        showKpiViews,

        // numberWithCommas,
        orderIntake,
        invoiced,
        // evaluateBudget,
        selectedDate,
        displayDate,
        testClick,
        lastUpdatedDate,

        budgetDayValue,
    };
  },

});
