
import { defineComponent, computed, ref } from 'vue'
// import {useStore} from 'vuex'
// import moment from 'moment'
// import { useRoute } from 'vue-router'
// import { usePrimeVue } from "primevue/config";
import colors from "@/tools/colors";
// import { AriaComponent } from 'echarts/components';
import { IChartModelData } from '@/models/ChartModels';

export default defineComponent({
  props: ['model'],
  setup(props) {

    const modelData = ref<IChartModelData>(props.model);

    const actualSum = computed(() => {
      if (modelData.value.type == 'line')
        return modelData.value.actualData[modelData.value.actualData.length-1];
      else
        return modelData.value.actualData.reduce((val: number, sum: number) => sum += val, 0);
    });
    const budgetSum = computed(() => {
      return modelData.value.budgetSum;
      // if (modelData.value.type == 'line')
      //   return modelData.value.budgetData.reduce((a, b) => Math.max(a, b), -Infinity);
      // else
      //   return modelData.value.budgetData.reduce((val: number, sum: number) => sum += val, 0);
    });
    const overBudget = computed(() => budgetSum.value > 0 && actualSum.value > budgetSum.value);

    const minYAxisVal = computed(() => {
      // const budgetVal = modelData.value.budgetData.reduce((a, b) => Math.max(a, b), -Infinity);
      return modelData.value.budgetDay * 1.1
    });
    const maxYAxisVal = 5000000;

    const testClick = () => {
      console.log("testClick");
    };
    const numberWithCommas = (x: number) => {
        // Crashes on Safari
        //https://caniuse.com/js-regexp-lookbehind
        //return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ");
        return x ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : "";
    }

    const barChartConfig = () => {
      const highYVal = modelData.value.actualData.reduce((a, b) => Math.max(a, b), -Infinity);
      // console.log(">> MAX ", highYVal);
      let yAxisVal = Math.max(minYAxisVal.value, Math.min(highYVal, maxYAxisVal));
      yAxisVal = Math.ceil(yAxisVal / 10000) * 10000;

      const barColors = new Array<string>();
      modelData.value.actualData.forEach((val: number, idx: number) => {
        //const budgetVal = modelData.value.budgetDay; // modelData.value.budgetData.length > idx ? modelData.value.budgetData[idx] : -1;
        let color = 'silver';
        if (modelData.value.budgetDay > 0) {
          color = val >= modelData.value.budgetDay ? 'rgba(0, 128, 0, 0.5)' : 'rgba(250, 128, 114, 0.5)';
        } 
        barColors.push(color);
      })

      var dataSeries = [];
      dataSeries.push({
        name: modelData.value.title,
        type: modelData.value.type,
        stack: "stack",
        color: colors.sperre_black,
        // radius: "55%",
        // center: ["50%", "60%"],
        data: modelData.value.actualData.map((o: any, idx: number) => {
          return {
              value: o,
              itemStyle: {color: barColors.length > idx ? barColors[idx] : 'silver'},
          };
        }),
        emphasis: {
          focus: "series",
          itemStyle: {
              shadowOffsetX: 0,
              shadowColor: colors.sperre_black
          }
        }
      });

      return {
        series: dataSeries,
        grid: {
          top: 20,
          bottom: 0,
          left: "auto",
          right: 0,
          containLabel: false,
          shadowColor: colors.sperre_black,
          gridLine: {
            show: true,
            lineStyle: {
                color: colors.sperre_black_30,
            }
          },
        },
        width: "80%",
        height: "70%",
        title: {
          show: false,
          text: "TEST",
          left: "center",
          zlevel: 0,
        },
        tooltip: {
            trigger: "item"
        },
        legend: {
            show: false,
        },
        xAxis: {
          type: "category",
          data: modelData.value.labels,
          axisLabel: {
              color: colors.sperre_black,
          },              
        },
        yAxis: {
          type: "value",
          position: "left",
          min: 0, 
          max: yAxisVal,
          axisLabel: {
            color: colors.sperre_black,
            formatter: function (data: any) {
                return numberWithCommas(data)
            }
          },
          axisLine: {
            show: true,
            lineStyle: {
                color: colors.sperre_black_30,
            }
          },
          splitLine: {
            lineStyle: {
                type: "dashed",
                color: colors.sperre_black_10,
            }
          },
        },
      };
    }

    const lineChartConfig = () => {
      const highYVal = modelData.value.actualData.reduce((a, b) => Math.max(a, b), -Infinity);
      // console.log(">> MAX ", highYVal);
      let yAxisVal = Math.max(minYAxisVal.value, Math.min(highYVal, maxYAxisVal));
      yAxisVal = Math.ceil(yAxisVal / 10000) * 10000;

      //const budgetVal = modelData.value.budgetDay; // modelData.value.budgetData.reduce((a, b) => Math.max(a, b), -Infinity);

      return {
        dataset: [
          {
              // source: lineChartData,
              source: modelData.value.actualData.map((val: any, idx: number) => {
                  // return [chart.timestamps[idx], val]
                  return {
                      timestamp: modelData.value.labels[idx],
                      amount: val,
                  }
              }),
              dimensions: ["timestamp", "amount"]
          }, 
        ],
        series: [
            {
                name: "Total Amount",
                type: "line",
                color: colors.sperre_black_30,
                datasetIndex: 0,
                encode: {
                    x: "timestamp",
                    y: "amount"
                },
                markLine: {
                    symbol: "none",
                    data: [
                        {
                            name: "Goal",
                            yAxis: modelData.value.budgetDay,
                            lineStyle: { type: "solid", color: colors.sperre_green, width: "2", opacity: 1.0 },
                            tooltip: {
                                formatter: "Goal: {c}"
                            }
                        }
                    ]
                },
                
                
            },
        ],
        grid: {
            top: 20,
            bottom: 0,
            left: "auto",
            right: 0,
            containLabel: false,
            shadowColor: colors.sperre_black,
            gridLine: {
                show: true,
                lineStyle: {
                    color: colors.sperre_black_30,
                }
            },
        },
        width: "80%",
        height: "70%",
        title: {
            show: false,
        },
        legend: {
            show: false,
        },
        tooltip: {
            trigger: "item",
        },
        dataZoom: [
            {
                type: "inside",
                filterMode: "empty",
                start: 0,
                end: 100,
            },
        ],
        xAxis: {
            type: "time",
            axisLabel: {
                color: colors.sperre_black,
                rotate: 45,
            }
        },
        yAxis: {
            splitLine: {
                lineStyle: {
                    type: "dashed"
                }
            },
            min: 0, 
            max: yAxisVal,
            axisLabel: {
                color: colors.sperre_black,
                formatter: function (data: any) {
                    return numberWithCommas(data)
                }
            },
            axisLine: {
                show: true,
                lineStyle: {
                    color: colors.sperre_black_30,
                }
            },                
        },            
      };
    }

    const chartConfig = computed(() => {
        // console.debug("Create Chart Config", JSON.parse(JSON.stringify(modelData.value)));

        if (modelData.value.type == 'bar') {
          return barChartConfig();
        } else {
          return lineChartConfig();
        }

        
    });

    return {

      modelData,
      actualSum,
      budgetSum,
      overBudget,
      barChartConfig: chartConfig,

      numberWithCommas,
      testClick,
    }
  }
});
