import axios from 'axios';

export interface ApiOptions {
    mutation: string;
    params: any;
}

const api = {
    async get(url: string) {
        // si.log("URL", url);
        const config = {
            // headers: {
            //   Authorization: "Bearer " + (await this.getAccessToken()).accessToken
            // }
        };

        return new Promise((resolve, reject) => {
            axios
                .get(url, config)
                .then((response) => {
                    if (response) {
                        // si.log("==Respones Headers", response);
                        if (response.status == 200) {
                            if (
                                response.headers['content-type'].includes(
                                    'application/json'
                                )
                            ) {
                                // can be: content-type: "application/json; charset=utf-8"
                                if (process.env.NODE_ENV == 'development') {
                                    // console.log(`API:Response from '${url}'\n`, response.data);
                                }
                                resolve(response.data);
                            } else {
                                reject(
                                    new Error(
                                        `Got response with content-type '${response.headers['content-type']}'`
                                    )
                                );
                            }
                        } else if (response.status === 204) {
                            resolve(response.data);
                        } else {
                            // si.log("==Respones Headers", response);
                            reject(
                                new Error(
                                    `Got response with status '${response.status}'`
                                )
                            );
                        }
                    } else
                        reject(new Error('Got response with undefined data'));
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    async post(url: string, options: ApiOptions) {
        // si.log("URL", url);
        const data = options.params;
        const config = {
            // headers: {
            //   Authorization: "Bearer " + (await this.getAccessToken()).accessToken
            // }
        };

        return new Promise((resolve, reject) => {
            axios
                .post(url, data, config)
                .then((response) => {
                    if (response) {
                        // si.log("==Respones Headers", response);
                        if (response.status == 200) {
                            if (process.env.NODE_ENV == 'development') {
                                console.log('API:Response', response.data);
                            }
                            resolve(response.data);
                        } else if (response.status === 204) {
                            resolve(response.data);
                        } else {
                            // si.log("==Respones Headers", response);
                            reject(
                                new Error(
                                    `Got response with status '${response.status}'`
                                )
                            );
                        }
                    } else
                        reject(new Error('Got response with undefined data'));
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
};

export default api;
