import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6236a4b7"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("span", {
      ref: "emitter",
      class: "emitter",
      title: _ctx.currentValue,
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.playConfetti()))
    }, null, 8, _hoisted_1)
  ]))
}