import { createStore } from 'vuex';
import api from '../services/api';
import moment from 'moment'

interface KpiStoreState {
    viewState: string,
    kpis: any,
    esgKpis: any,
    
    aftersalesSelectedDay: any;
    aftersalesBudget: any;
    aftersales: any;
    aftersalesDay: any;

    budgetLoaded: boolean;
    budgetDayValue: number;
    budgetWeekValue: number;
    budgetMonthValue: number;
}

/*
    VUEX Modules: https://betterprogramming.pub/how-to-structure-a-massive-vuex-store-for-a-production-app-f4bf84018409
*/

export default createStore({
    state: {
        viewState: "Unknown",
        kpis: [],
        esgKpis: [],

        aftersalesSelectedDay: undefined,
        aftersalesBudget: undefined,
        aftersales: undefined,
        aftersalesDay: undefined,

        budgetLoaded: false,
        budgetDayValue: 0,
        budgetWeekValue: 0,
        budgetMonthValue: 0,
    },
    mutations: {
        setAuthorizedViewState(state: KpiStoreState, data: any) {
            state.viewState = "Authorized";
        },
        resetAftersales(state: KpiStoreState, data: any) {
            state.kpis = [];
        },
        updateSelectedDate(state: KpiStoreState, selectedDate: any) { 
            state.aftersalesSelectedDay = moment(selectedDate);
        }, 
        loadAftersalesBudget(state: KpiStoreState, data: any) {
            console.debug("Mutation:loadAftersalesBudget", data);
            state.aftersalesBudget = data;
        },
        loadAftersalesAggregates(state: KpiStoreState, data: any) {
            console.debug("Mutation:loadAftersalesAggregates", data);
            state.aftersales = data;
        },
        loadAftersalesDay(state: KpiStoreState, data: any) {
            console.debug("Mutation:loadAftersalesDay", data);
            state.aftersalesDay = data;
        }, 

        resetEsgKpiData(state: KpiStoreState, data: any) {
            state.esgKpis = [];
        },
        loadEsgKpiData(state: KpiStoreState, data: any) {
            console.debug("Mutation:loadEsgKpiData", data);
            state.esgKpis = data;
        },

        addKpi(state: KpiStoreState, data: any) {
            // console.debug("Mutation:addKpi", data);
            const kpi = {
                id: data.kpiId,
                key: data.key,
                period: data.period, 
                label: data.label, 
                tmp: 0, 
                actual: 0, 
                budget: 0, 
                change: 0,
                //diff: {rate: 0, time: undefined, value: 0}
            };
            state.kpis.push(kpi);
            // console.debug("## State:kpis", state.kpis);
        },

        resetAftersalesViewData(state: KpiStoreState) {
            state.kpis.forEach((a: any) => {
                a.tmp = 0;
                a.budget = 0;
                a.actual = 0;
                a.change = 0;
                //a.diff = {rate: 0, time: undefined, value: 0};
            });
        },

        updateAftersalesSelectedDay(state: KpiStoreState, data: any) {
            console.debug("Mutation:updateAftersalesSelectedDay", data);
            state.aftersalesSelectedDay = moment(data);
        },
        updateAftersalesBudgetViewData(state: KpiStoreState, data: any) {
            console.debug("Mutation:updateAftersalesBudgetViewData", data);
            const kpis = state.kpis.slice();

            if (data.days) {
              console.debug("Mutation:updateAftersalesBudgetViewData::GOT Budget DATA");
              state.budgetLoaded = true;
              // state.budgetDayValue = 900000; // TEST VALUE
            }

            data.aggregates.forEach((x: any) => {
                const aggs = kpis.filter((a: any) => a.period == x.period);
                aggs.forEach((agg: any) => {
                    agg.budget = x.value;
                });               
            });
            const dayAgg = data.aggregates.find((x: any) => x.period == 'day');
            if (dayAgg && state.budgetDayValue == 0) {
              state.budgetDayValue = dayAgg.value;
            }
            const weekAgg = data.aggregates.find((x: any) => x.period == 'week');
            if (weekAgg && state.budgetWeekValue == 0) {
              state.budgetWeekValue = weekAgg.value;
            }
            const monthAgg = data.aggregates.find((x: any) => x.period == 'month');
            if (monthAgg && state.budgetMonthValue == 0) {
              state.budgetMonthValue = monthAgg.value;
            }

            state.kpis = kpis;
        },
        updateAftersalesAggregatesViewData(state: KpiStoreState, data: any) {
            console.debug(`>> Mutation:updateAftersalesAggregatesViewData`, data);
            const kpis = state.kpis.slice();

            data.aggregates.forEach((x: any) => {
                const kpi = kpis.find((a: any) => a.key == x.key && a.period == x.period);
                if (kpi) {
                    console.debug("Update KPIs::kpi", kpi, " from agg:", x);
                    kpi.tmp = x.value;
                }
            });

            /*
                Change Detection! + Updating actual 
            */
            kpis.forEach((a: any) => {
                if (a.tmp != a.actual) {
                    if (a.actual > 0) {
                        a.change++;
                        console.log(`Change -> ${a.label} (${a.period}) Actual: ${a.actual} New: ${a.tmp}`);
                    }
                    a.actual = a.tmp;
                } else {
                    console.debug(`No Change -> ${a.label} (${a.period}) Actual: ${a.actual} New: ${a.tmp}`);
                }
            });

            state.kpis = kpis;
        },
        updateAftersalesDayViewData(state: KpiStoreState) {
            
            const sortedSalesDay = state.aftersalesDay.slice().sort((s1: any, s2: any) => {
                if (s1.time > s2.time) return 1;
                else if (s1.time < s2.time) return -1;
                else return 0;
            });
            // console.log("sortedSalesDay", sortedSalesDay);

            const periodKpis = state.kpis.filter((k: any) => k.period == 'day');
            periodKpis.forEach((periodKpi: any) => {
                const sortedSalesPeriodByKpi = sortedSalesDay.filter((x: any) => x.key == periodKpi.key);
                if (sortedSalesPeriodByKpi) {
                    const labels: string[] = [];
                    const dataSeries: number[] = [];
                    // const budgetSeries: number[] = [];
                    const timestamps: any[] = [];
                    let lastValue = 0;
                    let lastDiff = 0;
                    let lastTime = '';
                    sortedSalesPeriodByKpi.forEach((x: any) => {
                        const timeLbl = moment(x.time).format("HH:mm");
                        const isoTimeLbl = moment(x.time).toISOString();
                        // console.info("DayKpi- Time: ", isoTimeLbl, " Value: ", x.value);
                        labels.push(timeLbl);
                        dataSeries.push(x.value);    
                        // budgetSeries.push(state.budgetDayValue);
                        timestamps.push(isoTimeLbl);
                        
                        lastDiff = x.value - lastValue;
                        lastValue = x.value;
                        lastTime = timeLbl;
                    });
                    const newChart = {
                        type: 'line',
                        labels: labels,
                        data: dataSeries,
                        timestamps: timestamps,
                    }
                    periodKpi.chart = newChart;
                    const newComp = {
                      title: periodKpi.label,
                      type: 'line',
                      labels: timestamps,
                      actualData: dataSeries,
                      // budgetData: budgetSeries,
                      budgetDay: state.budgetDayValue,
                      budgetSum: state.budgetDayValue,
                    }
                    periodKpi.comp = newComp;

                    periodKpi.diff = {
                        value: lastDiff,
                        time: lastTime,
                        rate: Math.ceil(lastDiff / 10000)
                    }
                    console.log(">>>>>>>> periodKpi.comp", periodKpi.comp);
                    // console.debug(">>> DIFF - Key: ", periodKpi.key, "-> diff: ", periodKpi.diff);
                    // periodKpi.diff.rate = Math.ceil(a.diff.value / 10000);
                    // periodKpi.diff.time = 
                }
            });
        },
        // updateAftersalesYearChartViewData(state: KpiStoreState, data: any) {
        //     const periodKpis = state.kpis.filter((k: any) => k.period == 'year');
        //     periodKpis.forEach((periodKpi: any) => {
        //         const sortedSalesPeriodByKpi = state.aftersales.days.filter((x: any) => x.key == periodKpi.key);
        //         if (sortedSalesPeriodByKpi) {
        //             const labels: string[] = Array.from(Array(12), (x, idx) => moment().month(idx).format('MMM'));
        //             const dataSeries: number[] = Array.from(Array(12), () => 0);
        //             sortedSalesPeriodByKpi.forEach((x: any) => {
        //                 const month = moment(x.day).month();
        //                 dataSeries[month] += x.value;    
        //             });
        //             const newChart = {
        //                 type: 'bar',
        //                 labels: labels,
        //                 data: dataSeries,
        //             }
        //             periodKpi.chart = newChart;
        //         }
        //     });
        // },
        updateAftersalesMonthChartViewData(state: KpiStoreState, data: any) {
            const selectedDay = moment(state.aftersalesSelectedDay);
            const selectedMonth = selectedDay.month();
            const monthDays = selectedDay.daysInMonth();

            const periodKpis = state.kpis.filter((k: any) => k.period == 'month');
            periodKpis.forEach((periodKpi: any) => {
                const sortedSalesPeriodByKpi = state.aftersales.days.filter((x: any) => 
                    x.key == periodKpi.key && moment(x.day).month() == selectedMonth);
                if (sortedSalesPeriodByKpi) {
                    const labels: string[] = Array.from(Array(monthDays), (x, idx) => (idx).toString());
                    const dataColors: string[] = Array.from(Array(monthDays), (x, idx) => 'silver');
                    const dataSeries: number[] = Array.from(Array(monthDays), () => 0);
                    const budgetSeries: number[] = Array.from(Array(monthDays), () => 0);
                    sortedSalesPeriodByKpi.forEach((x: any) => {
                        const date = moment(x.day).date();
                        dataSeries[date] = x.value;
                        //budgetSeries[date] = state.budgetDayValue; //x.value > 0 ? state.budgetDayValue : 0;
                        // budgetSeries[date] = x.value > 0 ? state.budgetDayValue : 0;
                        dataColors[date] = x.value < state.budgetDayValue ? 'rgba(250, 128, 114, 0.5)' : 'rgba(0, 128, 0, 0.5)';  
                    });
                    const newChart = {
                        type: 'bar',
                        labels: labels,
                        data: dataSeries,
                        dataColors: dataColors,
                    }
                    periodKpi.chart = newChart;
                    const newComp = {
                      title: periodKpi.label,
                      type: 'bar',
                      labels: labels,
                      actualData: dataSeries,
                      budgetData: budgetSeries,
                      budgetDay: state.budgetDayValue,
                      budgetSum: state.budgetMonthValue,
                    }
                    periodKpi.comp = newComp;
                    // console.log(">>>>>>>> periodKpi.comp", periodKpi.comp);
                }
            });
        },
        updateAftersalesWeekChartViewData(state: KpiStoreState, data: any) {
            const selectedDay = moment(state.aftersalesSelectedDay);
            const weekStartDay = moment(selectedDay).startOf('isoWeek');

            const periodKpis = state.kpis.filter((k: any) => k.period == 'week');
            periodKpis.forEach((periodKpi: any) => {
                const sortedSalesPeriodByKpi = state.aftersales.days.filter((x: any) => 
                    x.key == periodKpi.key 
                    && moment(x.day) >= weekStartDay
                    && moment(x.day) <= selectedDay);
                if (sortedSalesPeriodByKpi) {
                    const labels: string[] = Array.from(Array(7), (x, idx) => moment().weekday(idx+1).format('ddd'));
                    const dataSeries: number[] = Array.from(Array(7), () => 0);
                    // const budgetSeries: number[] = Array.from(Array(7), () => 0);
                    const dataColors: string[] = Array.from(Array(7), (x, idx) => 'silver');
                    sortedSalesPeriodByKpi.forEach((x: any) => {
                        const weekday = moment(x.day).weekday();
                        dataSeries[weekday -1] = x.value;
                        // budgetSeries[weekday -1] = state.budgetDayValue;
                        dataColors[weekday -1] = x.value < state.budgetDayValue ? 'rgba(250, 128, 114, 0.5)' : 'rgba(0, 128, 0, 0.5)';  
                    });
                    const newChart = {
                        type: 'bar',
                        labels: labels,
                        data: dataSeries,
                        dataColors: dataColors,
                    }
                    periodKpi.chart = newChart;
                    const newComp = {
                      title: periodKpi.label,
                      type: 'bar',
                      labels: labels,
                      actualData: dataSeries,
                      // budgetData: budgetSeries,
                      budgetDay: state.budgetDayValue,
                      budgetSum: state.budgetWeekValue,
                    }
                    periodKpi.comp = newComp;
                    // console.log(">>>>>>>> periodKpi.comp", periodKpi.comp);
                }
            });
        }
    },
    actions: {
        loadAftersalesBudget({ commit }, options: any) {
            commit('resetAftersalesViewData');
            const url = process.env.VUE_APP_APIURL + `/api/salesbudget/year/${options.company}/${options.code}?aggdate=${options.selectedDay.format("YYYY-MM-DD")}`;
            // console.log("API ", url);
            api.get(url)
                .then((data) => {
                    // console.log("DATA ", data);
                    commit('setAuthorizedViewState', data);
                    commit('loadAftersalesBudget', data);
                    commit('updateAftersalesBudgetViewData', data);
                })
                .catch((ex) => {
                    console.error("Action:loadAftersalesBudget", ex);
                })
        },
        loadAftersalesAggregates({ commit }, options: any) {
            const url = process.env.VUE_APP_APIURL + `/api/salesaggregates/year/${options.company}/${options.code}?aggdate=${options.selectedDay.format("YYYY-MM-DD")}`;
            // console.log("API ", url);
            api.get(url)
                .then((data) => {
                    // console.log("DATA ", data);
                    commit('loadAftersalesAggregates', data);
                    commit('updateAftersalesAggregatesViewData', data);
                    commit('updateAftersalesWeekChartViewData', data);
                    commit('updateAftersalesMonthChartViewData', data);
                    // commit('updateAftersalesYearChartViewData', data);
                })
                .catch((ex) => {
                    console.error("Action:loadAftersalesAggregates", ex);
                })
        },
        loadAftersalesDay({ commit }, options: any) {
            const url = process.env.VUE_APP_APIURL + `/api/salesaggregates/day/${options.company}/${options.code}?aggdate=${options.selectedDay.format("YYYY-MM-DD")}`;
            // console.log("API ", url);
            api.get(url)
                .then((data) => {
                    commit('loadAftersalesDay', data);
                    commit('updateAftersalesDayViewData', data);
                })
                .catch((ex) => {
                    console.error("Action:loadAftersalesDay", ex);
                })
        },
        loadEsgKpiData({ commit }, options: any) {
            commit('resetEsgKpiData');
            const url = process.env.VUE_APP_APIURL + `/api/esgkpi/period/${options.company}/${options.code}`;
            // console.log("API ", url);
            api.get(url)
                .then((data) => {
                    // console.log("DATA ", data);
                    commit('setAuthorizedViewState', data);
                    commit('loadEsgKpiData', data);
                })
                .catch((ex) => {
                    console.error("Action:loadEsgKpis", ex);
                })
        },

    },
    modules: {},
});
