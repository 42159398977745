
import { defineComponent, onMounted, ref, reactive } from 'vue';

import { ChartModelData } from '@/models/ChartModels';

// https://school.geekwall.in/p/SkD7MqoSN
// import { TimelineLite, TweenLite } from 'gsap';
// import gsap from 'gsap';

export default defineComponent({
  setup() {
    const confettiVal = ref(0);

    const modelUnder = new ChartModelData('Under', 'bar');
    modelUnder.actualData = [1000001, 1000000, 1000000, 1000000, 0, 0, 0];
    modelUnder.budgetData = [1000001, 1000001, 1000001, 1000001, 0, 0, 0];
    modelUnder.labels = ["Mon","Tue","Wed","Thu","Fri","Sat","Sun"];

    const modelOver = new ChartModelData('Over', 'bar');
    modelOver.actualData = [1500000, 1000000, 1500000, 1000000, 0, 0, 0];
    modelOver.budgetData = [1000001, 1000001, 1000001, 1000001, 0, 0, 0];
    modelOver.labels = ["Mon","Tue","Wed","Thu","Fri","Sat","Sun"];

    const testData = reactive({
      actualVsBudgetCard: {
        under: modelUnder,
        over: modelOver,
      }
    });

    /*
        Move Boxes
    */
    // const moveBoxes = () => {
    //     // move box
    //     // const timeline = new TimelineLite();
    //     gsap.to("#box", 1, { x: 200, rotation: 90 });

    //     gsap.to("#box2", {duration: 0, x: 400, backgroundColor: "blue"})
    //     gsap.to("#box2", {duration: 1, y: -300, backgroundColor: "white"})
    // }

    onMounted(() => {
        console.log("onMounted");


    });

    const changeCompData = () => {
      console.log("Changing data");
      // modelUnder.actual = modelUnder.actual + 10;
    }

    const testConfetti = () => {
        console.log("testConfetti");
        confettiVal.value ++;
    }

    return {
        testConfetti,
        confettiVal,

        testData,
        changeCompData,
    }
  }
});
