
import { defineComponent, onMounted, computed, watch, ref, onBeforeUnmount } from 'vue'
import {useStore} from 'vuex'
import moment from 'moment'
// import si from '@/tools/si'
// import api from "../services/api";
import { useRoute } from 'vue-router'
import { usePrimeVue } from "primevue/config";
import colors from "@/tools/colors";

export default defineComponent({
  setup() {
    const primevue = usePrimeVue();
    // https://www.primefaces.org/primevue/showcase/#/locale
    if (primevue.config && primevue.config.locale) {
        primevue.config.locale.firstDayOfWeek = 1;
    }
    const route = useRoute()
    const store = useStore();

    const selectedDate = ref(new Date());
    const lastUpdatedDate = ref(new Date());
    const selectedDay = computed(() => {
        // console.log("Date Changed to:", selectedDate.value)
        return moment(selectedDate.value.toISOString())
    }); //moment("2021-08-30");


    watch(selectedDate, () => {
        console.log("New Date", selectedDay.value.format("DD-MM-yyyy"));
        store.commit('updateSelectedDate', selectedDate.value);
        reload(true);
    });

    const kpis = computed(() => store.state.kpis);
    const packed = computed(() => store.state.kpis.filter((k:any) => k.key == 'Warehouse.Salesorder.Packed'));
    const invoiced = computed(() => store.state.kpis.filter((k:any) => k.key == 'Aftersales.Invoiced.Spareparts'));
    const showKpiViews = computed(() => store.state.viewState == "Authorized");
    const budgetDayValue = computed(() => store.state.budgetDayValue);
    const refreshIntervalMs = 10000;

    // const createChartData = (chart: any) => { //labels, dataTitle, data, dataColors) => {
    //     return {
    //         labels: chart.labels,
    //         datasets: [
    //             {
    //                 label: 'dataset',
    //                 data: chart.data,
    //                 backgroundColor: chart.dataColors ? chart.dataColors : 'rgba(192, 192, 192, 0.5)',
    //             }
    //         ]
    //     }
    // };

    const createBarChartData = (chart: any) => {
        console.debug("createBarChartData", JSON.parse(JSON.stringify(chart)));
        var dataSeries = [];
        dataSeries.push({
            name: chart.key,
            type: chart.type,
            stack: "stack",
            color: colors.sperre_black,
            radius: "55%",
            center: ["50%", "60%"],
            // data: chart.data,
            data: chart.data.map((o: any, idx: number) => {
                return {
                    value: o,
                    itemStyle: {color: chart.dataColors[idx]},
                };
            }),
            emphasis: {
                focus: "series",
                itemStyle: {
                    // shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: colors.sperre_black
                }
            }
        });

        return {
            grid: {
                top: 20,
                bottom: 0,
                left: "auto",
                right: 0,
                containLabel: false,
                //show: true,
                shadowColor: colors.sperre_black,
                // borderColor: colors.sperre_black,
                gridLine: {
                    show: true,
                    lineStyle: {
                        color: colors.sperre_black_30,
                    }
                },
            },
            // left: 0,
            // top: 0,
            width: "80%",
            height: "70%",
            title: {
                show: false,
                text: "TEST",
                left: "center",
                zlevel: 0,
            },
            tooltip: {
                trigger: "item"
                //formatter: "<small>{b} : {c}</small>"
            },
            legend: {
                show: false,
                orient: "horizontal",
                align: "auto"
                // data: ['2018', '2019','2020', '2021']
            },
            xAxis: {
                type: "category",
                data: chart.labels,
                axisLabel: {
                    color: colors.sperre_black,
                    // formatter: function (data: any) {
                    //     return numberWithCommas(data)
                    // }
                },
                // axisLine: {
                //     show: true,
                //     lineStyle: {
                //         type: "dashed",
                //         color: colors.sperre_black_30,
                //     }
                // },
                
            },
            yAxis: {
                type: "value",
                position: "left",
                min: 0, 
                max: 2000000,
                axisLabel: {
                    color: colors.sperre_black,
                    formatter: function (data: any) {
                        return numberWithCommas(data)
                    }
                },
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: colors.sperre_black_30,
                    }
                },
                splitLine: {
                    lineStyle: {
                        type: "dashed",
                        color: colors.sperre_black_10,
                    }
                },
            },            
            series: dataSeries
        };
    };

    const createLineChartData = (chart: any) => {
        console.debug("createLineChartData", JSON.parse(JSON.stringify(chart)));
        return {
            grid: {
                top: 20,
                bottom: 0,
                left: "auto",
                right: 0,
                containLabel: false,
                //show: true,
                shadowColor: colors.sperre_black,
                // borderColor: colors.sperre_black,
                gridLine: {
                    show: true,
                    lineStyle: {
                        color: colors.sperre_black_30,
                    }
                },
            },
            width: "80%",
            height: "70%",
            title: {
                show: false,
            },
            legend: {
                show: false,
                // orient: "horizontal",
                // align: "auto"
            },
            tooltip: {
                trigger: "item",
                // axisPointer: {
                //     type: "cross"
                // }
            },
            dataZoom: [
                {
                    type: "inside",
                    filterMode: "empty",
                    start: 0,
                    end: 100,
                    // startValue: "2022-02-24T07:00:00Z",
                    // endValue: "2022-02-24T17:00:00Z",
                },
                // {
                //     start: 80,
                //     end: 100
                // }
            ],
            xAxis: {
                type: "time",
                // data: chart.timestamps,
                axisLabel: {
                    color: colors.sperre_black,
                    rotate: 45,
                    // formatter: function (data: any) {
                    //     return numberWithCommas(data)
                    // }
                }
            },
            yAxis: {
                splitLine: {
                    lineStyle: {
                        type: "dashed"
                    }
                },
                min: 0, 
                max: 1500000,
                axisLabel: {
                    color: colors.sperre_black,
                    formatter: function (data: any) {
                        return numberWithCommas(data)
                    }
                },
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: colors.sperre_black_30,
                    }
                },                
            },
            dataset: [
                {
                    // source: lineChartData,
                    source: chart.data.map((val: any, idx: number) => {
                        // return [chart.timestamps[idx], val]
                        return {
                            timestamp: chart.timestamps[idx],
                            amount: val,
                        }
                    }),
                    dimensions: ["timestamp", "amount"]
                }, 
            ],
            series: [
                {
                    name: "Total Amount",
                    type: "line",
                    color: colors.sperre_black_30,
                    datasetIndex: 0,
                    encode: {
                        x: "timestamp",
                        y: "amount"
                    },
                    markLine: {
                        symbol: "none",
                        data: [
                            {
                                name: "Goal",
                                yAxis: budgetDayValue.value,
                                lineStyle: { type: "solid", color: colors.sperre_green, width: "2", opacity: 0.5 },
                                tooltip: {
                                    formatter: "Goal: {c}"
                                }
                            }
                        ]
                    },
                    
                    
                },
            ]
        };
    };

    const kpiChartData = ref(Array<any>());

    watch(kpis, () => {
        // console.log("Update KPI chart data!", kpis.value);
        kpis.value.forEach((x: any) => {
            if (x.chart) {
                if (x.chart.type == "bar")
                    kpiChartData.value[x.id] = createBarChartData(x.chart);
                else if (x.chart.type == "line")
                    kpiChartData.value[x.id] = createLineChartData(x.chart);
            }
        });
    });

    const reload = (reloadBudget: boolean) => {
        const selectedDay = moment(selectedDate.value.toISOString());
        const company = route.query.company
        const code = route.query.code

        if (reloadBudget) {
            store.commit('resetAftersales');
            store.commit('addKpi', {kpiId: 10, key: 'Aftersales.Invoiced.Spareparts', period: 'day', label: 'Today'});
            store.commit('addKpi', {kpiId: 11, key: 'Aftersales.Invoiced.Spareparts', period: 'week', label: 'Week to date'});
            store.commit('addKpi', {kpiId: 12, key: 'Aftersales.Invoiced.Spareparts', period: 'month', label: 'Month to date'});

            store.commit('addKpi', {kpiId: 21, key: 'Warehouse.Salesorder.Packed', period: 'day', label: 'Today'});
            store.commit('addKpi', {kpiId: 22, key: 'Warehouse.Salesorder.Packed', period: 'week', label: 'Week to date'});
            store.commit('addKpi', {kpiId: 23, key: 'Warehouse.Salesorder.Packed', period: 'month', label: 'Month to date'});

            console.log("Loading Sales Budget...", selectedDay.format("DD.MM.YYYY HH:mm:ss"))
            store.dispatch('loadAftersalesBudget', {company, code, selectedDay})
        }
        console.log("Loading Sales Aggregates for year...", selectedDay.format("DD.MM.YYYY HH:mm:ss"));
        store.dispatch('loadAftersalesAggregates', {company, code, selectedDay})

        console.log("Loading Sales for day...", selectedDay.format("DD.MM.YYYY HH:mm:ss"));
        store.dispatch('loadAftersalesDay', {company, code, selectedDay})

    };

    const numberWithCommas = (x: number) => {
        // Crashes on Safari
        //https://caniuse.com/js-regexp-lookbehind
        //return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ");
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");

    }

    const evaluateBudget = (agg: any) => {
      return agg.budget > 0 && agg.actual >= agg.budget;
    }

    let pollingInterval: any = undefined;
    const pollData = () => {
      pollingInterval = setInterval(() => {
        reload(false);
      }, refreshIntervalMs)
    }

    onMounted(() => {
      console.log("== KPI:Warehouse - onMounted");
      // const code = route.query.code
      reload(true);
      pollData();
    });

    onBeforeUnmount(() => {
      clearInterval(pollingInterval);
    });

    const displayDate = () => {
        return moment(selectedDate.value.toISOString()).format("dddd DD.MM.yyyy");
    }

    const testClick = () => {
        packed.value[0].change++;
    }

    const chartOptions = ref({
        responsive: true,
        hoverMode: "index",
        stacked: false,
        animation: {
            duration: 0
        },
        plugins: {
            legend: {
                display: false,
            }
        },
        scales: {
            y: {
                min: 0,
                suggestedMax: 1500000,
                ticks: {
                    // color: 'pink'
                },
            }
        }
    });

    return {
        showKpiViews,
        
        numberWithCommas,
        invoiced,
        packed,
        evaluateBudget,
        selectedDate,
        displayDate,
        testClick,
        lastUpdatedDate,

        kpiChartData,
        chartOptions,

    };
  },

});
